import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  path = ['Empreendimento Licentia'];
  tabs = ['Unidades'];
  operationalData = {
    name: 'Empreendimento Licentia',
    type: 'Empreendimento',
    searchTooltip: 'Pesquisar Unidades Operacionais'
  };

  contentType = 'Projeto';
  panelData = {
    description: 'Visualize aqui as principais informações do seu Emprendimento',
    indicators: ['Condicionantes por projeto e por status', 'Condicionantes por projeto e por status',
      'Condicionantes por projeto e por status', 'Condicionantes por projeto e por status']
  };

  userForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private snackBar: MatSnackBar,) {
    this.userForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      last_name: ['', Validators.required],
      name: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      phone: ['', [Validators.required, Validators.pattern(/^\d{11}$/)]], // Formato com 11 dígitos
      perfil: ['', Validators.required],
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.userForm.valid) {


      /*this.snackBar.open('Perfil atualizado com sucesso!', 'Fechar', {
        duration: 1500,
        panelClass: ['snack-bar-success']
      });
      this.dialogRef.close(this.form.value);

      setTimeout(() => {
        window.location.reload();
      }, 1500);*/
    } else {
      console.log('Formulário inválido');
    }
  }
}
