import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SignInService } from '../sign-in.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-renew-password',
  templateUrl: './signin-set-password.component.html',
  styleUrls: ['./signin-set-password.component.scss'],
})
export class SigninSetPasswordComponent implements OnInit {
  passwordFormGroup: FormGroup;
  hash: string | null = null;
  passwordsMatch: boolean = true;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private service: SignInService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.hash = this.route.snapshot.queryParamMap.get('hash');

    this.passwordFormGroup = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            this.passwordValidator.bind(this),
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      { validators: this.passwordMatchValidator }
    );

    this.passwordFormGroup.valueChanges.subscribe(() => {
      this.checkPasswords();
    });
  }

  passwordValidator(control: any): { [key: string]: boolean } | null {
    const value = control.value;
    if (value) {
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
        return { noSymbol: true }; // Sem símbolo especial
      }
      if (!/[A-Z]/.test(value)) {
        return { noUppercase: true }; // Sem letra maiúscula
      }
    }
    return null;
  }

  passwordMatchValidator(group: FormGroup): { [key: string]: boolean } | null {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    return password === confirmPassword ? null : { passwordsDoNotMatch: true };
  }

  checkPasswords(): void {
    const password = this.passwordFormGroup.get('password').value;
    const confirmPassword = this.passwordFormGroup.get('confirmPassword').value;
    this.passwordsMatch = password === confirmPassword;
  }

  onSubmit(): void {
    if (this.passwordFormGroup.valid && this.hash) {
      const password = this.passwordFormGroup.get('password').value;
      const dados = {
        hashNewPassword: this.hash,
        newPassword: password,
      };

      this.service.updatePassword(dados).subscribe(
        () => {
          this.snackBar.open('Senha Alterada Com Sucesso', 'Fechar', {
            duration: 1600,
            panelClass: ['snack-bar-success'],
          });

          setTimeout(() => {
            window.location.href = '/';
          }, 1600);
        },
        (error) => {
          this.snackBar.open('Erro ao alterar senha', 'Fechar', {
            duration: 1600,
            panelClass: ['snack-bar-error'],
          });
        }
      );
    } else {
      console.log('Formulário inválido ou hash ausente.');
    }
  }
}
