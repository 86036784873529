import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecoverPasswordService } from './recover-password.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})

export class RecoverPasswordComponent implements OnInit {
  recoverPasswordFormGroup: FormGroup;
  recaptchaResponse: string | null = null;
  isSubmitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: RecoverPasswordService
  ) {
    (window as any).onCaptchaResolved = this.onCaptchaResolved.bind(this);
    (window as any).onCaptchaExpired = this.onCaptchaExpired.bind(this);
  }

  ngOnInit(): void {
    this.loadRecaptchaScript();
    this.recoverPasswordFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  loadRecaptchaScript(): void {
    if (!document.querySelector('script[src="https://www.google.com/recaptcha/api.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }

  onCaptchaResolved(response: string): void {
    this.recaptchaResponse = response;
    console.log('reCAPTCHA response:', response);
  }

  onCaptchaExpired(): void {
    this.recaptchaResponse = null;
    console.log('reCAPTCHA expirado.');
  }

  onSubmit(): void {
    this.recaptchaResponse = "123456"
    if (!this.recoverPasswordFormGroup || this.recoverPasswordFormGroup.invalid || !this.recaptchaResponse) {
      Object.keys(this.recoverPasswordFormGroup.controls).forEach((field) => {
        const control = this.recoverPasswordFormGroup.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
      alert('Por favor, preencha todos os campos e complete o reCAPTCHA.');
      return;
    }

    const emailControl = this.recoverPasswordFormGroup.get('email');
    const email = emailControl ? emailControl.value : null;

    this.service.sendRecoveryEmail(email, this.recaptchaResponse).subscribe(
      (response) => {
        console.log('Email enviado com sucesso:');
        this.isSubmitted = true;
      },
      (error) => {
        console.log('Email enviado com sucesso:');
        this.isSubmitted = true;
      }
    );
  }
}
