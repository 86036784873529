import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { SignInService } from './login/sign-in/sign-in.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoading = false;

  constructor(private service: SignInService, private router: Router) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const excludedRoutes = ['/authorize', '/authorize_external', '/', '/authorize'];
        const isExcluded = excludedRoutes.some(route => event.url.startsWith(route));

        if (isExcluded) {
          this.isLoading = true;
         setTimeout(() => {
            this.isLoading = false;
          }, 2000);
        } else {
          console.log("Não, é uma rota excluída");
        }
      }
    });
  }


}
