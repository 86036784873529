import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SignInService } from './sign-in.service';
import { ActivatedRoute, Params, Router, UrlSerializer } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { User } from './User.model';
import { CookieService } from 'ngx-cookie';
import { HttpErrorResponse } from '@angular/common/http';
import { first, flatMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { environment } from './../../../environments/environment';


const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  nextStep: boolean;
  email: string;
  hasAccounts: boolean;
  removeAccounts: boolean;
  accountsExpired = [];
  accounts = [];
  routeSubscription: Subscription;
  load = true;
  submitted = false;
  user: User = {
    client_id: null,
    redirect_uri: null,
    response_type: null,
    scope: null,
    nonce: null,
    state: null,
    prompt: null,
    response_mode: null,
    max_age: null,
    email: null,
    password: null
  };
  uri: string;
  redirectUri: string;
  redirectUrl: string;
  recaptchaResponse: string | null = null;

  constructor(private signInService: SignInService, private formBuilder: FormBuilder,
    private cookieService: CookieService, private router: Router, private serializer: UrlSerializer) {
    (window as any).onCaptchaResolved = this.onCaptchaLoaded.bind(this);
    (window as any).onCaptchaExpired = this.onCaptchaExpired.bind(this);
  }

  ngOnInit() {
    //this.loadRecaptchaScript();
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      password: ['', Validators.required]
    });
  }

  verifySession() {

    if (this.cookieService.get('device_token')) {

      this.signInService.getAccountsByDevice(this.user.max_age).subscribe((res: Array<string>) => {
        this.load = false;
        this.accounts = res;
        if (this.accounts.length > 0) {
          this.hasAccounts = true;
        }
      }, (error: HttpErrorResponse) => this.clearAccount());
    } else {
      this.clearAccount();
    }
  }

  clearAccount() {
    this.accounts = [];
    this.load = false;
  }

  ngOnDestroy() {

  }

  redirectURI(provider) {
    const tree = this.router.createUrlTree([], { queryParams: this.user });
    const params = this.serializer.serialize(tree).replace('/authorize?', '');

    return `${environment.api}/oauth2/authorize_external?external_provider=${provider}&${params}`;
  }

  // changeState(event) {
  //   this.nextStep = event.nextStep;
  //   this.user.email = event.email;
  //   this.hasAccounts = event.hasAccounts;
  //   this.removeAccounts = event.removeAccounts;
  // }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit(): void {
    this.submitted = true;
    /*if (this.loginForm.invalid || !this.recaptchaResponse) {
       alert('Por favor, preencha todos os campos e complete o reCAPTCHA.');
       return;
     }*/

    const { email, password } = this.loginForm.value;

    this.signInService.login(email, password).subscribe(
      () => this.router.navigate(['/dashboard']),
      (error) => console.error('Erro ao realizar login:', error)
    );
  }

  loginWithMicrosoft(): void {
    this.signInService.requestMicrosoftToken().subscribe(
      (response: any) => {

        if (response && response.url) {
          window.location.href = response.url;
        } else {
          console.error('URL de login não retornada:', response);
        }
      },
      (error) => {
        console.error('Erro ao iniciar login com Microsoft:', error);
      }
    );
  }

  loadRecaptchaScript(): void {
    if (!document.querySelector('script[src="https://www.google.com/recaptcha/api.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }

  onCaptchaLoaded(response: string): void {
    console.log(response);
    this.recaptchaResponse = response;

  }

  onCaptchaExpired(): void {
    this.recaptchaResponse = null;
    console.log('reCAPTCHA expirado.');
  }



}
