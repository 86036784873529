import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from "../../sidebar.service";
import { OperationUnitService } from '../../../operational-unit/service/operation-unit.service';
import { EnterpriseService } from '../../../enterprise/service/enterprise-service';
import { ProjectService } from '../../../project/service/project.service';
import { PermitionService } from '../../../permition/service/permition.service';

@Component({
  selector: 'app-mini-sidenav',
  templateUrl: './mini-sidenav.component.html',
  styleUrls: ['./mini-sidenav.component.scss']
})
export class MiniSidenavComponent implements OnInit {

  constructor(
    private sideBarService: SidebarService,
    private router: Router,
    private serviceOperation: OperationUnitService,
    private serviceProject: ProjectService,
    private serviceEnterprise: EnterpriseService,
    private servicePermition: PermitionService,
  ) { }

  operationalUnits: any[] = [];
  projectsAll: any[] = [];
  enterprise: any[] = [];
  permitionAll: any[] = [];
  showMenu: boolean = false;
  ngOnInit() {
    const profilesId = localStorage.getItem('profilesId');
   
    this.showMenu = profilesId && parseInt(profilesId, 10) != 6;

    this.loadProjectAll();
    this.loadOperationalUnitsAll();
    this.loadPermitionAll();
  }

  sideMenuToggle() {
    this.sideBarService.toggle();
  }



  loadOperationalUnitsAll() {
    this.serviceOperation.operationalUnitsAll().subscribe((response: any) => {
      if (response && response.operationalUnit) {
        this.operationalUnits = response.operationalUnit.map((unit: any) => ({
          id: unit.id,
          nameCompany: unit.name ? unit.name : '',
        }));

      } else {
        console.error('Nenhuma unidade operacional encontrada:', response);
      }
    }, (error) => {
      console.error('Erro ao carregar unidades operacionais:', error);
    });
  }

  loadProjectAll() {

    this.serviceProject.listProject().subscribe((response: any) => {
      if (response && response.content) {
        this.projectsAll = response.content.map((project: any) => ({
          id: project.operationalUnit.id,
          name: project.name ? project.name : '',
        }));

      } else {
        console.error('Nenhuma unidade operacional encontrada:', response);
      }
    }, (error) => {
      console.error('Erro ao carregar unidades operacionais:', error);
    });

  }

  loadPermitionAll() {

    this.servicePermition.allPermition().subscribe((response: any) => {
      if (response && response.content) {
        this.permitionAll = response.content.map((permition: any) => ({
          id: permition.id,
          name: permition.name ? permition.name : '',
        }));

      } else {
        console.error('Nenhuma Permissão encontrada:', response);
      }
    }, (error) => {
      console.error('Erro ao carregar Permissão:', error);
    });

  }

  /*loadEnterprise() {
    this.serviceEnterprise.operationalUnitsUUID(this.id).subscribe((response: any) => {
      if (response && response.operationalUnit) {

        this.enterprise = [
          {

            id: operationalUnit.id || '',
            name: operationalUnit.name || ''
          }
        ];

        if (this.paginator) {
          this.dataSource.paginator = this.paginator;
        }
        if (this.sort) {
          this.dataSource.sort = this.sort;
        }

      } else {
        console.error('Nenhuma unidade operacional encontrada:', response);
      }

    }, (error) => {
      console.error('Erro ao carregar unidades operacionais:', error);
    });
  }
    */

  changeRoute(route: string) {

    this.router.navigate([route]).then(success => {
      if (success) {
        console.log('Navigation is successful!');
      } else {
        console.log('Navigation has failed!');
      }
    });
  }

  export(): void {
    this.serviceOperation.export(localStorage.getItem('companyId')).subscribe(() => {
      console.log('Arquivo exportado com sucesso.');
    });
  }

}
