import { ELEMENT_DATA as AGENCY_EA } from '../../../project/organData';
import { ELEMENT_DATA as PROJECT_EA } from '../../../enterprise/projectData';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SidebarService } from "../../sidebar.service";
import { LoginResponse, SignInService, User } from "../../../login/sign-in/sign-in.service";
import { Router } from '@angular/router';
import { OperationUnitService } from '../../../operational-unit/service/operation-unit.service';
import { EnterpriseService } from '../../../enterprise/service/enterprise-service';
import { ProjectService } from '../../../project/service/project.service';
import { PermitionService } from '../../../permition/service/permition.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SideMenuComponent implements OnInit {
  userInfo: LoginResponse;
  user: User;
  projects = PROJECT_EA;
  agencys = AGENCY_EA;
  // permitions = PERMITION_EA;

  constructor(private sideBarService: SidebarService,
    private signinService: SignInService,
    private router: Router,
    private serviceOperation: OperationUnitService,
    private serviceProject: ProjectService,
    private serviceEnterprise: EnterpriseService,
    private servicePermition: PermitionService,
  ) {
    this.getUserResponse();
    this.user = this.signinService.getUserData();
  }


  operationalUnits: any[] = [];
  projectsAll: any[] = [];
  enterprise: any[] = [];
  permitionAll: any[] = [];
  showMenu: boolean = false;
  ngOnInit() {
    const profilesId = localStorage.getItem('profilesId');
  
    this.showMenu = profilesId && parseInt(profilesId, 10) !== 6;
    this.loadProjectAll();
    this.loadOperationalUnitsAll();
    this.loadPermitionAll();
  }

  sideMenuToggle() {
    this.sideBarService.toggle();
  }



  loadOperationalUnitsAll() {
    this.serviceOperation.operationalUnitsAll().subscribe((response: any) => {
      if (response && response.operationalUnit) {
        this.operationalUnits = response.operationalUnit.map((unit: any) => ({
          id: unit.id,
          nameCompany: unit.name ? unit.name : '',
        }));

      } else {
        console.error('Nenhuma unidade operacional encontrada:', response);
      }
    }, (error) => {
      console.error('Erro ao carregar unidades operacionais:', error);
    });
  }

  loadProjectAll() {

    this.serviceProject.listProject().subscribe((response: any) => {
      if (response && response.content) {
        this.projectsAll = response.content.map((project: any) => ({
          id: project.id,
          name: project.name ? project.name : '',
        }));

      } else {
        console.error('Nenhuma unidade operacional encontrada:', response);
      }
    }, (error) => {
      console.error('Erro ao carregar unidades operacionais:', error);
    });

  }

  loadPermitionAll() {

    this.servicePermition.allPermition().subscribe((response: any) => {
      if (response && response.content) {
        this.permitionAll = response.content.map((permition: any) => ({
          id: permition.id,
          name: permition.name ? permition.name : '',
        }));

      } else {
        console.error('Nenhuma Permissão encontrada:', response);
      }
    }, (error) => {
      console.error('Erro ao carregar Permissão:', error);
    });

  }

  closeSideMenu() {
    this.sideBarService.toggle();
  }

  getUserResponse() {
    this.userInfo = this.signinService.getUserResponse();
  }
  changeRoute(route: string) {
    this.router.navigateByUrl('/' + route);
  }

  export(): void {
    this.serviceOperation.export(localStorage.getItem('companyId')).subscribe(() => {
      console.log('Arquivo exportado com sucesso.');
    });
  }

}
