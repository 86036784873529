import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, of } from "rxjs";
import { ActivatedRoute, Params, Router, UrlSerializer } from '@angular/router';

export interface LoginResponse {
  email: string;
  expireIn: any;
  accessToken: string;
  user: { username: string, email: string, lastName: string, userId: number };
  companies: [{ company: string; companyId: number }];
  responseKeyClock: {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    token_type: string;
    session_state: string;
    scope: string;
  };
}

export interface User {
  username: string;
  email: string;
  lastName: string;
  userId: number;
}


export interface Companies {
  companies: [{ company: string; companyId: number }];
}

@Injectable({
  providedIn: 'root'
})
export class SignInService {
  isDropdownVisible = false;
  token: string;
  private userData: any = null;
  private companies: Companies;
  private loginReponse: LoginResponse;
  private user: User;

  constructor(private http: HttpClient, private router: Router) {

  }

  private setHeader(withCredentials: boolean) {
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'accept': '*/*',
        'Content-Type': 'application/json'
      }),

    };
  }

  // checkEmailNotTaken(email: string) {
  //   return this.http.get(`${environment.api}/sign_in_service/email_lookup?email=${email}`, this.setHeader(false)).pipe(
  //     map((res: any) => {
  //       return res.taken ? true : null;
  //     }),
  //     catchError((err) => {
  //       return err;
  //     })
  //   );
  // }

  requestCheck(uriParams) {
    return this.http.get(`${environment.api}sign_in_service/request_validation?${uriParams}`, this.setHeader(false));
  }

  getAccountsByDevice(maxAge) {
    const requestURI = maxAge ? `${environment.api}sessions?max_age=${maxAge}` : `${environment.api}/sessions`;
    return this.http.get(requestURI, this.setHeader(true)).pipe(
      map((res: any) => res)
    );
  }

  removeAccount(sessionToken: string) {
    return this.http.delete(`${environment.api}sessions/${sessionToken}`, this.setHeader(true)).pipe(
      catchError((exception) => exception)
    );
  }

  login(email: string, password: string) {
    return this.http.post<LoginResponse>(`${environment.api}login`, { email, password })
      .pipe(
        map(response => {
          if (response && response.accessToken) {
            this.storeToken(response);
            this.userData = response;
            return true;
          }
          return false;
        }),
        catchError(error => {
          console.error(error);
          return of(false);
        })
      );
  }

  getToken(): string {
    return localStorage.getItem('access_token');
  }

  getUserData(): any {
    const userData = localStorage.getItem('user');
    return this.user = JSON.parse(userData);
    // return localStorage.getItem('userName');
  }

  getCompaniesData(): any {
    const companiesData = localStorage.getItem('companies');
    return this.companies = JSON.parse(companiesData);
  }

  getUserResponse() {
    const userResponse = localStorage.getItem('user_response');
    return this.loginReponse = JSON.parse(userResponse);
  }

  private storeToken(response: LoginResponse): void {
    localStorage.setItem('access_token', response.accessToken);
    //localStorage.setItem('refresh_token', response.refresh_token);
    localStorage.setItem('expires_in', (Date.now() + response.expireIn).toString());
    localStorage.setItem('user', JSON.stringify(response.user));
    localStorage.setItem('user_response', JSON.stringify(response));
    localStorage.setItem('companies', JSON.stringify(response.companies));

    let currentCompanyId;
    if (localStorage.getItem('companyId') != null) {
      currentCompanyId = parseInt(localStorage.getItem('companyId') || '0', 10);
    } else {
      currentCompanyId = localStorage.getItem('companies')[0];
    }
    const companies = JSON.parse(localStorage.getItem('companies') || '[]');

    if (companies && Array.isArray(companies)) {
      const company = companies.find((item: any) => item.companyId === currentCompanyId);
      localStorage.setItem('profilesId', company.profilesId);
      if (company.profilesId == 6) {
        location.href = 'users-list';
      }
    } else {
      console.error('Nenhuma lista de empresas encontrada ou formato inválido.');
    }


  }

  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_in');
    this.userData = null;

  }

  isAuthenticated(): boolean {

    const token = this.getToken();
    const expiry = localStorage.getItem('expires_in');
    if (token && expiry) {

      const isExpired = Date.now() >= parseInt(expiry);
      if (isExpired) {
        this.logout();
        return false;
      }
      return true;
    }
    return false;
  }

  requestMicrosoftToken(): Observable<any> {
    return this.http.get('https://bff-dev.licentia.digital/v1/link-sso-azure', {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'text',
    }).pipe(
      map((response: string) => ({ url: response })),
      catchError((error) => {
        console.error('Erro ao obter URL de login:', error);
        return of(null);
      })
    );
  }

  updatePassword(dados: any): Observable<any> {
    const url = 'https://bff-dev.licentia.digital/v1/user/change-password';
    const headers = { 'Content-Type': 'application/json' };

    return this.http.post(url, dados, { headers, responseType: 'text' }).pipe(
      map((response: string) => {
        console.log('Senha redefinida com sucesso');
        return { url: response };
      }),
      catchError((error) => {
        console.error('Erro ao redefinir senha:', error);
        return of(null);
      })
    );
  }




}
