import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermitionService {
  private baseUrl: string = environment.api;

  constructor(private http: HttpClient) { }

  private setHeader(withCredentials: boolean) {
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Cache-Control': 'no-cache',
        'accept': '*/*',
        'Content-Type': 'application/json'
      }),
    };
  }

  allPermition(): Observable<any> {
    const url = `${this.baseUrl}permition?page=0&size=50&companyId=${localStorage.getItem('companyId')}`;
    return this.http.get(url, this.setHeader(true)).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of([]);
      })
    );
  }

  listDynamicDataPermition(projectId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-data?type=PERMITION&staticAnswers=${projectId}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listUserCompany(): Observable<any> {

    return this.http.get(`${this.baseUrl}user/all-users-company?page=0&size=50&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar usuarios:', error);
          return of([]);
        })
      );
  }

  listDynamicFormPermition(companyId): Observable<any> {

    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Permition`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDataDynamicForms(id): Observable<any> {

    return this.http.get(`${this.baseUrl}operational-units/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicPermition(): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${localStorage.getItem('companyId')}&type=Permition`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }


  listPermition(id): Observable<any> {

    return this.http.get(`${this.baseUrl}permition/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listAddProgram(dados): Observable<any> {

    return this.http.post(`${this.baseUrl}program`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listProjectUUID(uuid): Observable<any> {
    return this.http.get(`${this.baseUrl}project/project/${uuid}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }


  dynamicProgram(): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${localStorage.getItem('companyId')}&type=Program`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  createDynamicFormAnswers(dados): Observable<any> {

    return this.http.post(`${this.baseUrl}mongo/create-dynamic-form-answers`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  createFolderPermition(dados): Observable<any> {
    return this.http.post(`${this.baseUrl}permition/create-folder`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listProject(): Observable<any> {
    return this.http.get(`${this.baseUrl}project/all?page=0&size=50&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

}
