import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OperationUnitService } from '../service/operation-unit.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-remove-dialog',
  templateUrl: './remove-dialog.component.html',
  styleUrls: ['../operational-unit.component.scss']
})
export class RemoveDialogComponent {
  nomeCompany: any;
  constructor(
    public dialogRef: MatDialogRef<RemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: OperationUnitService,
    private snackBar: MatSnackBar
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  confirmRemove(): void {
    this.service.removeOperational(this.data.uuid).subscribe(
      (response: any) => {

        this.snackBar.open('Usuario Removido da Unidade!', 'Fechar', {
          duration: 1500,
          panelClass: ['snack-bar-success']
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);

        this.dialogRef.close();

      }
    );
  }
}
