import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class OperationUnitService {
  private baseUrl: string = environment.api;

  constructor(private http: HttpClient) { }

  private setHeader(withCredentials: boolean) {
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Cache-Control': 'no-cache',
        'Accept': '*/*',
        'Content-Type': 'application/json'
      }),
    };
  }

  operationalUnitsAll(): Observable<any> {
    return this.http.get(`${this.baseUrl}operational-units/all?companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar unidades operacionais:', error);
          return of([]);
        })
      );
  }

  addUnit(dados): Observable<any> {
    return this.http.post(`${this.baseUrl}operational-units`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listUserCompany(): Observable<any> {
    return this.http.get(`${this.baseUrl}user/all-users-company?page=1&size=50&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar usuarios:', error);
          return of([]);
        })
      );
  }

  listUserCompanyPage(page: number, size: number): Observable<any> {
    return this.http.get(`${this.baseUrl}user/all-users-company?page=${page}&size=${size}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar usuarios:', error);
          return of([]);
        })
      );
  }

  addOperational(unit: any): Observable<any> {

    return this.http.post(`${this.baseUrl}operational-units`, unit, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao adicionar unidade operacional:', error);
          return of(null);
        })
      );
  }

  listDataDynamicForms(id): Observable<any> {

    return this.http.get(`${this.baseUrl}operational-units/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicForms(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=OperationalUnit`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  editOperational(company: string, operationalUnitId: string, name: string, detail: string, responsibleId: number): Observable<any> {
    const operational = {
      company: company,
      operationalUnitId: operationalUnitId,
      name: name,
      detail: detail,
      responsibleId: responsibleId,
    };

    return this.http.put(`${this.baseUrl}operational-units`, operational, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao editar unidade operacional:', error);
          return of(null);
        })
      );
  }


  removeOperational(unitId: string): Observable<any> {
    const url = `${this.baseUrl}operational-units/remove/${unitId}`;

    const headers = this.setHeader(true).headers;

    return this.http
      .delete(url, { headers })
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover unidade operacional:', error);
          return throwError(error);
        })
      );
  }

  removeUserOperational(userId: number): Observable<any> {
    const url = `${this.baseUrl}user/user-company`;

    const headers = this.setHeader(true).headers;

    const body = {
      userId: userId,
      companyId: localStorage.getItem('companyId')
    };

    return this.http
      .request('DELETE', url, { headers, body })
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover unidade operacional:', error);
          return throwError(error);
        })
      );
  }



  addOwner(userId: any, operationalUnitId: any) {
    const projectData = {
      companyId: localStorage.getItem('companyId'),
      userId: userId,
      operationalUnitId: operationalUnitId,
    };
  
    return this.http.post(`${this.baseUrl}operational-units/add-owner`, projectData, this.setHeader(true)).pipe(
      tap((response: any) => response),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }


  listremoveUserCompany(companyId: any) {
    return this.http.get(`${this.baseUrl}user/all-users-company?page=0&size=10&companyId=${companyId}`, this.setHeader(true)).pipe(
      catchError((error) => {
        console.error('Erro ao Listar Usuarios', error);
        return of(null);
      })
    );
  }

  export(companyId: any) {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const formattedDate = `${day}-${month}-${year}_${hours}-${minutes}`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      'Cache-Control': 'no-cache',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Tipo MIME para arquivos Excel
      'Content-Type': 'application/json'
    });

    return this.http.post(
      `${this.baseUrl}export?companyId=${companyId}`,
      {},
      { headers, responseType: 'blob' }
    ).pipe(
      catchError((error) => {
        console.error('Erro ao Exportar Dados', error);
        return of(null);
      }),
      map((blob: Blob) => {
        if (blob) {

          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = `Exportação_Licentia_${formattedDate}.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
        }
      })
    );
  }



  // camps  Dynamics
  dynamicCommitment(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Commitment`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicProgram(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Program`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicContract(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Contract`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicConstraint(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Constraint`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicAdditionalInformation(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=AdditionalInformation`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicPermition(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Permition`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicOperationalUnit(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=OperationalUnit`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicSupplier(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Supplier`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicProject(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Project`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

}
