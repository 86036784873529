import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnterpriseService } from '../service/enterprise-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-remover-project-dialog',
  templateUrl: './remover-owner.component.html',
  styleUrls: ['../enterprise.component.scss']
})
export class RemoverOwnerDialogComponent {
  projectId: any;
  ownerForm: FormGroup;
  dadosOwners: any[] = [];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RemoverOwnerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: EnterpriseService,
    private snackBar: MatSnackBar,
  ) {
    this.dadosOwners = data.dadosProjeto.owners;

    this.ownerForm = this.fb.group({
      name: ['', Validators.required],
      id: ['', Validators.required],
      projectId: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openRemoverDialog(dados: any): void {
    this.service.removeOwner(dados.id, dados.projectId).subscribe((response: any) => {
      console.log('Owner Removido Com Sucesso', response);
      this.snackBar.open('Owner Removido Com Sucesso!', 'Fechar', {
        duration: 1500,
        panelClass: ['snack-bar-success']
      });
      this.dialogRef.close();

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    },
      (error) => {
        this.snackBar.open('Erro ao remover owners!', 'Fechar', {
          duration: 1500,
          panelClass: ['snack-bar-danger']
        });
        this.dialogRef.close();

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    );
  }
}
