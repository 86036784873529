import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EnterpriseService {
  private baseUrl: string = "";

  constructor(
    private http: HttpClient,
  ) {
    this.baseUrl = environment.api;
  }

  private setHeader(withCredentials: boolean) {
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Cache-Control': 'no-cache',
        'accept': '*/*',
        'Content-Type': 'application/json'
      }),
    };
  }

  deleteUser(dados: { companyId: string; userId: string; operationalUnitId: string }): Observable<any> {
    const url = `${this.baseUrl}operational-units/remove-owner`;
  
    const body = {
      companyId: dados.companyId,
      userId: dados.userId,
      operationalUnitId: dados.operationalUnitId,
    };
  
    const headers = this.setHeader(true).headers;
  
    return this.http
      .request('DELETE', url, {
        headers,
        body,
        observe: 'response', 
      })
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover usuário:', error);
          return throwError(error);
        })
      );
  }

  operationalUnitsUUID(id): Observable<any> {
    return this.http.get(`${this.baseUrl}operational-units/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listUserCompany(): Observable<any> {
    return this.http.get(`${this.baseUrl}user/all-users-company?page=0&size=50&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar usuarios:', error);
          return of([]);
        })
      );
  }

  addProject(companyId: string, operationalUnit: string, name: string, phase: string, observation: string, responsibleId: string): Observable<any> {
    const projectData = {
      projectId: '',
      companyId: companyId,
      operationalUnit: operationalUnit,
      name: name,
      phase: phase,
      observation: observation,
      responsibleId: responsibleId
    };

    return this.http.post(`${this.baseUrl}project`, projectData, this.setHeader(true)).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  editProject(projectId: string, companyId: string, name: string, phase: string, observation: string, responsibleId: string): Observable<any> {
    const projectData = {
      projectId: projectId,
      companyId: companyId,
      operationalUnit: projectId,
      name: name,
      phase: phase,
      observation: observation,
      responsibleId: responsibleId
    };

    return this.http.put(`${this.baseUrl}project`, projectData, this.setHeader(true)).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  addOwner(idsOwners: any[], projectId: any) {
    const projectData = {
      companyId: localStorage.getItem('companyId'),
      usersId: idsOwners,
      projectId: projectId,
    };

    return this.http.post(`${this.baseUrl}project/add-owner`, projectData, this.setHeader(true)).pipe(
      tap((response: any) => {
      
        if (response.status == 200) {
          return true
        } else {
          return false
        }
      }),
      catchError((error) => {
        return error;
      })
    );
  }

  removeOwner(idsOwners: any, projectId: any) {
    const url = `${this.baseUrl}project/remove-owner`;

    const headers = this.setHeader(true).headers;

    const body = {
      companyId: localStorage.getItem('companyId'),
      usersId: [idsOwners],
      projectId: projectId,
    };

    return this.http
      .request('DELETE', url, { headers, body })
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover unidade operacional:', error);
          return throwError(error);
        })
      );
  }

  dynamicOperationalUnit(id): Observable<any> {
    return this.http.get(`${this.baseUrl}operational-units/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicForms(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=OperationalUnit`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicDataProjetct(projectId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-data?type=PROJECT&staticAnswers=${projectId}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  createDynamicFormAnswers(dados): Observable<any> {
    return this.http.post(`${this.baseUrl}mongo/create-dynamic-form-answers`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicFormProject(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Project`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicForm(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=PROJECT`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  createFolderUnit(dados): Observable<any> {
    return this.http.post(`${this.baseUrl}operational-units/create-folder`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  addFolder(dados): Observable<any> {
    return this.http.post(`${this.baseUrl}operational-units/create-folder`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }
}
